/* TeamPage.css */
.team-page {
    padding: 20px;
}

.game-section {
    text-align: center;
}

.team-banners {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.team-banner {
    width: 100px;
    height: auto;
    border-radius: 10px;
}
.team-selector-container {
    text-align: center;
    padding: 20px;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 15px;
    padding: 20px;
}

.team-title {
    font-size: 20px; /* Tamaño de fuente más grande */
    font-weight: bold;
    margin-bottom: 15px; /* Espacio inferior ajustado */
    color: #83b985; /* Color verde para un tema de soccer */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Sombra de texto */
    text-transform: uppercase; /* Texto en mayúsculas */
    letter-spacing: 2px; /* Espaciado entre letras */
    font-family: 'Arial Black', sans-serif; /* Fuente más impactante */
}

.team-logo {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: transform 0.2s, border-color 0.2s;
}

.team-logo.selected {
    border-color: #007bff;
}

.team-logo:hover {
    transform: scale(1.1);
}

.team-logo-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
/* Container general */
.form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    color: #333;
}

/* Estilos del formulario */
.player-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-sizing: border-box;
}

/* Contenedor de columnas */
.form-columns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

/* Columna izquierda: inputs y selects */
.left-column {
    flex: 1 1 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Columna derecha: checkboxes */
.right-column {
    flex: 1 1 35%;
}

/* Agrupación de campos en filas */
.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Grupos de campos: se adaptan en ancho mínimo */
.form-group {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
}

/* Estilos básicos para inputs y selects */
.form-input,
.form-select {
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid #bbb;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
}

/* Responsive: en pantallas pequeñas, las columnas se organizan verticalmente */
@media (max-width: 800px) {
    .form-columns {
        flex-direction: column;
    }

    .left-column,
    .right-column {
        flex: 1 1 100%;
    }
}

/* Estilos para checkbox customizado */
.checkbox-container {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.checkbox-container input {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkbox-label {
    position: absolute;
    cursor: pointer;
    background-color: #bbb;
    border-radius: 24px;
    width: 100%;
    height: 100%;
    transition: background-color 0.3s;
}

.checkbox-label::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    transition: transform 0.3s;
}

.checkbox-container input:checked+.checkbox-label {
    background-color: #4caf50;
}

.checkbox-container input:checked+.checkbox-label::after {
    transform: translateX(16px);
}

/* Estilos para cada check-toggle; forzamos ancho completo para uniformidad */
.check-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
}

/* La etiqueta del checktoggle ocupará el mismo espacio en cada celda */
 .check-label {
    width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Distribución en grilla para los check-toggles con ancho fijo */
.check-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 10px;
}

/* Estilos para mostrar elementos seleccionados en selects múltiples */
.selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 8px;
}

.selected-item {
    background-color: #e0e0e0;
    padding: 4px 8px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.selected-item button {
    margin-left: 4px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #d32f2f;
}

/* Estilo para el botón de enviar */
.form-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #1976d2;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-button:hover {
    background-color: #1565c0;
}
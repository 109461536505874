.update-position-container {
    text-align: center;
    padding: 20px;
    background: rgba(0, 0, 50, 0.8);
    /* Azul oscuro con transparencia */
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    margin: auto;
    color: white;
}

.title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
}

.player-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    display: block;
    border: 3px solid #00c3ff;
    box-shadow: 0 0 10px #00c3ff;
}

.general-position {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    color: #ffcc00;
    /* Amarillo para destacar */
}

.positions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
}

.position-button {
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    background: #1e3a8a;
    /* Azul oscuro */
    color: white;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}

.position-button:hover {
    background: #2563eb;
    /* Azul más claro */
    box-shadow: 0 0 10px #2563eb;
}

.position-button.selected {
    background: #4caf50;
    box-shadow: 0 0 15px #4caf50;
    transform: scale(1.05);
}

.save-button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background: #ffcc00;
    color: black;
    margin-top: 20px;
    transition: all 0.3s;
}

.save-button:hover {
    background: #ffdd44;
    box-shadow: 0 0 10px #ffcc00;
}

.save-button:disabled {
    background: #555;
    color: #aaa;
    cursor: not-allowed;
    box-shadow: none;
}

.player-clubs {
    display: flex;
    justify-content: center;
    /* Centra los escudos horizontalmente */
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.club-logo {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 5px;
}


/* Botones de enlaces */
.player-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.link-button {
    background-color: #6cc221;
    color: white;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-weight: bold;
    transition: background 0.3s;
    display: inline-block;
}

.besoccer {
    background-color: #29b573;
    color: white;
}

.transfermarkt {
    background-color: #cf1818;
    color: white;
}

.auto-complete-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background: #ff7f50;
    /* Color anaranjado suave */
    color: white;
    margin-top: 15px;
    transition: background 0.3s, transform 0.3s;
}

.auto-complete-button:hover {
    background: #ff9966;
    transform: scale(1.03);
}

.skip-button-player {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background: #999;
    /* Color gris */
    color: white;
    margin-top: 15px;
    transition: background 0.3s, transform 0.3s;
}

.skip-button-player:hover {
    background: #bbb;
    transform: scale(1.03);
}

.auto-mode-button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    color: white;
    /* Estado por defecto: inactivo */
    background-color: #d32f2f;
    /* Rojo para "Off" */
}

.auto-mode-button:hover {
    transform: scale(1.05);
}

/* Clase para cuando el modo está activado */
.auto-mode-button.active {
    background-color: #4caf50;
    /* Verde para "On" */
}


.link-button:hover {
    filter: brightness(1.2);
}
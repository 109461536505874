.player-combiner-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh;
}

.team-banners {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 25px;
}

.team-banner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Campo de juego: más largo de arriba a abajo */
.field-player {
    position: relative;
    width: 100%;
    height: 600px;
    background: #2d5f2d;
    border: 4px solid white;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.center-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    border: 2px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.3;
}

.half-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    transform: translateY(-50%);
    opacity: 0.3;
}

/* Rectángulo de posición vacío con etiqueta */
.position-rectangle {
    width: 100%;
    max-width: 80px;
    height: 30px;
    border: 2px dashed white;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-label {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
}

/* Resalta las posiciones disponibles para asignar el jugador seleccionado */
.highlight-available .position-rectangle {
    background: rgba(0, 255, 0, 0.3);
    border-color: green;
    cursor: pointer;
}

.position-container {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

/* Contenedor para el jugador asignado */
.player-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    min-height: 70px;
}

/* Imagen del jugador */
.player-img-container {
    width: 70px;
    height: 70px;
    margin-bottom: 4px;
}

.player-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.player-name-container {
    padding-top: 90px;
    width: 100%;
    text-align: center;
}

.player-name {
    font-size: 12px;
    color: #fff;
    margin: 0;
}

.error-message {
    color: #ff4444;
    text-align: center;
    margin-top: 15px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

/* Contenedor de victoria que se posiciona en el flujo, siempre debajo de la cancha */
.victory-screen {
    background: linear-gradient(135deg, #1f1c2c, #928dab);
    border-radius: 15px;
    padding: 20px 30px;
    color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    margin: 20px auto 0;
    /* Siempre debajo de la cancha */
    opacity: 0;
    animation: slideUp 0.8s forwards;
}

/* Animación: desde 20px abajo y opacidad 0 hasta posición final sin desplazamiento */
@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.victory-message {
    font-size: 2.2em;
    margin-bottom: 20px;
    padding-bottom: 30px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0;
}

.victory-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

/* Los botones se estiran igual */
.victory-buttons button {
    flex: 1;
    padding: 12px 0;
    border: none;
    border-radius: 50px;
    font-size: 1.1em;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Botón para jugar de nuevo */
.play-again-btn {
    background: linear-gradient(45deg, #ff416c, #ff4b2b);
    color: #fff;
}

/* Botón para compartir/descargar */
.share-btn {
    background: linear-gradient(45deg, #2ecc71, #27ae60);
    color: #fff;
}

.victory-buttons button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


/* Mensaje de selección de posición */
.selection-message {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin: 15px 0;
    font-size: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.selection-message p {
    margin: 5px 0;
}


.skip-btn {
    display: inline-block;
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    border: 1px solid #555;
    color: #d4d1d1;
    padding: 8px 8px;
    border-radius: 15px;
    font-size: 0.8em;
    cursor: pointer;
    transition: all 0.2s ease;
    line-height: 1;
    vertical-align: middle;
    align-self: center;
}

.skip-btn:hover:not(:disabled) {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.skip-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    border-color: #ccc;
    color: #ccc;
}

.help-btn {
    background: transparent;
    border: 1px solid #555;
    color: #555;
    padding: 8px 8px;
    font-size: 0.8em;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 10px;
}

.help-btn:hover {
    background: #555;
    color: #fff;
}

.help-message {
    background: transparent;
    color: #d8d6d6;
    font-size: small;
}

.selection-message {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin: 15px 0;
    font-size: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.selection-message p {
    margin: 5px 0;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adaptaciones para pantallas medianas */
@media (max-width: 768px) {
    .field-player {
        height: 400px;
        border-width: 3px;
    }

    .center-circle {
        width: 80px;
        height: 80px;
        border-width: 1.5px;
    }

    .half-line {
        height: 1.5px;
    }

    .position-container {
        width: 120px;
    }

    .position-rectangle {
        height: 25px;
        font-size: 8px;
        padding: 1px;
    }

    .position-label {
        font-size: 8px;
    }

    .player-img-container {
        width: 45px;
        height: 45px;
    }

    .player-name {
        font-size: 8px;
    }

    .player-name-container {
        padding-top: 60px;
        width: 100%;  /* O podrías definir un valor fijo: width: 220px; */
        text-align: center;
    }

    .victory-screen {
        padding: 15px 20px;
        font-size: 1.5em;
    }

    .victory-message {
        font-size: 1.8em;
    }

    .victory-buttons button {
        font-size: 1em;
    }
}

/* Adaptaciones para pantallas pequeñas */
@media (max-width: 480px) {
    .field-player {
        height: 300px;
        border-width: 2px;
    }

    .center-circle {
        width: 60px;
        height: 60px;
        border-width: 1px;
    }

    .position-container {
        width: 95px;
    }

    .position-rectangle {
        height: 15px;
        font-size: 7px;
        max-width: 30px;
    }

    .position-label {
        font-size: 7px;
    }

    .player-img-container {
        width: 37px;
        height: 37px;
    }

    .player-name-container {
        padding-top: 45px;
        width: 100%;  /* O podrías definir un valor fijo: width: 220px; */
        text-align: center;
    }

    .player-name {
        font-size: 6px;
        color: #fff;
        margin: 0;
    }

    .victory-screen {
        padding: 15px 20px;
        font-size: 1.1em;
    }

    .victory-message {
        font-size: 1.1em;
    }

    .victory-buttons button {
        font-size: 0.7em;
    }
}